<template>
  <div class="characterManage">
     <el-container>
        <!-- 左侧树结构 -->
      <el-collapse-transition>
        <el-aside>
          <div class="aside-title">机构列表</div>
          <el-tree
            :props="TreedefaultProps"
            :load="loadNode"
            lazy
            @node-click="handleNodeTreeClick"
          ></el-tree>
        </el-aside>
      </el-collapse-transition>
      <!-- 右侧内容区 -->
        <el-main>
            <!-- 卡片A区域 -->
            <el-card class="box-card card-one" shadow="never">
            <!-- 搜索框区域 -->
            <el-form inline :model="form" class="demo-form-inline">
                <el-form-item label="角色名称">
                <el-input v-model="form.roleName" placeholder="角色名称" clearable></el-input>
                </el-form-item>
                <el-form-item class="Abtn">
                <el-button type="primary" @click="selectBtn()" icon="el-icon-search">搜索</el-button>
                <!-- <el-button type="primary" @click="addDialogVisible = true" icon="el-icon-plus">添加</el-button> -->
                <el-button type="primary" @click="addDialogVisible = true" v-if="btnList.includes(102)">添加</el-button>
                <el-button
                    type="primary"
                    @click="setBtn()"
                    icon="el-icon-setting"
                    v-if="btnList.includes(104)"
                >设置权限</el-button>
                </el-form-item>
            </el-form>
            </el-card>
            <!-- 卡片B区域 -->
            <el-card class="box-card card-two" shadow="never">
            <div class="cbtn">
                <!-- 表格区域 -->
                <el-row>
                <el-table
                    stripe
                    border
                    highlight-current-row
                    :data="tableData"
                    :header-cell-style="rowClass"
                    @current-change="handleCurrent"
                    :default-checked-keys="selectDate"
                >
                    <el-table-column prop="roleName" label="角色名称" align="center"></el-table-column>
                    <el-table-column prop="status" label="状态" align="center">
                    <template slot-scope="scope">
                        <span effect="dark" type="success" v-if="scope.row.status==='1'">{{ '正常' }}</span>
                        <span type="info" effect="dark" v-else>{{ '停用' }}</span>
                    </template>
                    </el-table-column>
                    <el-table-column prop="remark" label="角色备注" align="center"></el-table-column>
                    <el-table-column label="操作" align="center" min-width="100px">
                    <template slot-scope="scope">
                        <el-button size="mini" type="success" @click="look(scope.row)">查看详情</el-button>
                        <el-button
                        size="mini"
                        type="primary"
                        icon="el-icon-edit"
                        @click="edit(scope.row)"
                        v-if="btnList.includes(103)"
                        >修改</el-button>
                        <!-- <el-button
                        size="mini"
                        type="danger"
                        icon="el-icon-delete-solid"
                        @click="open(scope.row)"
                        >删除</el-button>-->
                    </template>
                    </el-table-column>
                </el-table>
                </el-row>
                <!-- 分页区域 -->
                <el-row class="paging">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="queryInfo.pageNo"
                    :page-sizes="[1, 10, 20, 50]"
                    :page-size="queryInfo.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total"
                ></el-pagination>
                </el-row>
            </div>
            <!-- 添加用户弹出框 -->
            <el-dialog :visible.sync="addDialogVisible" class="add-dialog" @close="addDialogClose()">
                <span slot="title" class="dialog-Title">添加角色</span>
                <el-form :model="addForm" inline ref="addFormRef" :rules="addFormRules">
                <!-- 第一行 -->
                <el-row>
                    <el-form-item label="角色名称" prop="roleName">
                    <el-input v-model="addForm.roleName" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="状态" prop="status">
                    <el-select v-model="addForm.status" placeholder="请选择状态" filterable>
                        <el-option label="正常" value="1"></el-option>
                        <el-option label="停用" value="2"></el-option>
                    </el-select>
                    </el-form-item>
                    <el-form-item label="角色备注" prop="remark">
                    <el-input v-model="addForm.remark" autocomplete="off"></el-input>
                    </el-form-item>
                </el-row>
                </el-form>
                <div slot="footer" class="dialog-footer">
                <el-button type="info" @click="addDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="addUser()">确 定</el-button>
                </div>
            </el-dialog>
            <!-- 编辑用户弹出框 -->
            <el-dialog :visible.sync="editDialogVisible" class="edit-dialog" @close="editDialogClose()">
                <span slot="title" class="dialog-Title">编辑角色信息</span>
                <el-form :model="editForm" inline ref="editFormRef" :rules="editFormRules">
                <!-- 第一行 -->
                <el-row>
                    <el-form-item label="角色名称" prop="roleName">
                    <el-input v-model="editForm.roleName" autocomplete="off" :disabled="disabled"></el-input>
                    </el-form-item>
                    <el-form-item label="状态" prop="status">
                    <el-select
                        v-model="editForm.status"
                        placeholder="请选择状态"
                        filterable
                        :disabled="disabled"
                    >
                        <el-option label="正常" value="1"></el-option>
                        <el-option label="停用" value="2"></el-option>
                    </el-select>
                    </el-form-item>
                    <el-form-item label="角色备注" prop="remark">
                    <el-input v-model="editForm.remark" autocomplete="off" :disabled="disabled"></el-input>
                    </el-form-item>
                </el-row>
                </el-form>
                <div slot="footer" class="dialog-footer">
                <el-button type="info" @click="editDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="editParams()">确 定</el-button>
                </div>
            </el-dialog>
            <!-- 设置权限对话框 -->
            <el-dialog
                :visible.sync="limitDialogVisible"
                class="limit-dialog"
                @close="limitDialogClosed()"
            >
                <span slot="title" class="dialog-Title">设置角色权限</span>
                <p class="tips"><i class="el-icon-question"></i> 机构账号权限，只能分配订单管理及人员管理</p>
                <el-tree
                :data="menuList"
                :props="defaultProps"
                :default-checked-keys="menuIds"
                empty-text="暂无数据"
                show-checkbox
                ref="tree"
                node-key="uuid"
                check-strictly
                @check="menuChange"
                @check-change="handleCheckChange"
                >
                <span slot-scope="{ node, data }">
                    <span v-if="node.level===1">
                    <i class="el-icon-folder-opened"></i>
                    {{data.menuName}}
                    </span>
                    <span v-else-if="node.level===2">
                    <i class="el-icon-files"></i>
                    {{data.menuName}}
                    </span>
                    <span v-else-if="node.level===3">
                    <i class="el-icon-files"></i>
                    {{data.menuName}}
                    </span>
                    <span v-else>
                    <i class="el-icon-folder"></i>
                    {{data.menuName}}
                    </span>
                </span>
                </el-tree>
                <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="checkAll()">全 选</el-button>
                <el-button type="primary" @click="InvertSelection()">反 选</el-button>
                <el-button type="primary" @click="editlimit()">确 定</el-button>
                <el-button type="primary" @click="limitDialogVisible = false">取 消</el-button>
                </div>
            </el-dialog>
            </el-card>
        </el-main>
     </el-container>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  inject: ['reload'],
  name: 'characterManage',
  components: {},
  data () {
    return {
      TreedefaultProps: {
        label: 'name',
        children: [],
        isLeaf: 'leaf'
      }, // 树结构配置对象
      form: {
        roleName: ''
      }, // 搜索框表单对象
      addForm: {
        roleName: '',
        remark: '',
        uuid: '',
        status: '1'
      }, // 添加角色的表单对象
      addFormRules: {
        roleName: [
          { required: true, message: '请输入角色名称', trigger: 'blur' }
        ]
      }, // 添加角色的验证规则对象
      addDialogVisible: false, // 控制添加角色对话框的显示与隐藏
      tableData: [], // 角色列表
      editDialogVisible: false, // 控制修改角色对话框的显示与隐藏
      editForms: {
        rolename: '',
        remark: '',
        uuid: '',
        status: 1
      }, // 修改角色表单的数据对象
      delForm: { uuid: '' }, // 删除角色表单的数据对象
      editFormRules: {
        rolename: [
          { required: true, message: '请输入角色名称', trigger: 'blur' }
        ]
      }, // 修改表单的验证规则对象
      queryInfo: {
        pageNo: 1,
        pageSize: 10,
        applicationCode: '10000'
      },
      total: 0, // 总数据条数
      selectForm: { uuid: '' }, // 设置权限表单
      limitDialogVisible: false, // 控制权限对话框的显示与隐藏
      dataTree: [],
      defaultProps: {
        children: 'twoMenusList',
        label: 'menuName'
      }, // 树状图配置对象
      limitform: {}, // 权限对象
      menuList: [], // 所有菜单
      disabled: true,
      editForm: {},
      permission: [], // 权限列表
      selectDate: [], // 默认勾选的节点
      asyncMenuList: [],
      btnList: sessionStorage.getItem('btnList'),
      btns: [],
      menu: [],
      node: '',
      menuIds: []

    }
  },
  created () {
    this.getCateList()
    this.getMenuList()
    this.getPermission()
  },
  methods: {
    // 点击左侧树节点触发
    async handleNodeTreeClick (data, node) {
      if (node.level != 0) {
        // console.log(node);
        this.node = node
        this.getCateList(this.node)
      }
    },

    loadNode (node, resolve) {
      console.log(node.level, 'node节点')
      // 如果展开第一级节点，从后台加载一级节点列表
      if (node.level == 0) {
        this.getProvince(resolve)
      }
      // 如果展开其他级节点，动态从后台加载下一级节点列表
      if (node.level == 1) {
        this.getCity(node, resolve)
        this.getCateList(node)
      }
      if (node.level == 2) {
        this.getDistrict(node, resolve)
        this.getCateList(node)
      }
      if (node.level == 3) {
        this.node = node
        this.getCateList(node, resolve)
      }
    },
    // 获取省份--调试完成
    async getProvince (resolve) {
      const { data: res } = await this.$http.post(
        '/userManageServer/area/selectProvinceList',
        {}
      )
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取省份失败')
      res.data.forEach((item) => {
        item.name = item.province
        if (res.data.count == 0) {
          item.hasChild = false
        } else {
          item.hasChild = true
        }
      })
      const obj = {
        corpId: 0,
        createBy: null,
        createTime: null,
        hasChild: false,
        name: '机构',
        province: '机构',
        updateBy: null,
        leaf: true
      }
      res.data.push(obj)
      //   console.log(res.data, 'res.data')
      resolve(res.data)
    },
    // 获取市--调试完成
    async getCity (node, resolve) {
      const { data: res } = await this.$http.post(
        '/userManageServer/area/selectCityList',
        {
          province: node.data.uuid
        }
      )
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取市失败')

      if (resolve == null) {
        return res.data
      } else {
        res.data.forEach((item) => {
          item.name = item.cityName
          if (res.data.count == 0) {
            item.hasChild = false
          } else {
            item.hasChild = true
          }
        })

        resolve(res.data)
      }
      //  console.log(res.data);
    },
    // 获取区域--调试完成
    async getDistrict (node, resolve) {
      const { data: res } = await this.$http.post(
        '/userManageServer/area/selectDistrictList',
        {
          cityId: node.data.uuid
        }
      )
      // console.log(res.data);
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取区域失败')

      res.data.forEach((item) => {
        item.name = item.disName
        if (res.data.count == 0) {
          item.hasChild = false
        } else {
          item.hasChild = true
        }
      })
      // console.log(res.data);
      resolve(res.data)
    },
    // 获取所有角色---已调试完成
    async getCateList (node, resolve) {
    //   console.log(node, 'nodenode')
      if (node) {
        this.queryInfo.baseId = node.data.corpId
      }
      const { data: res } = await this.$http.post('/userManageServer/role/selectRoleList', JSON.parse(JSON.stringify(this.queryInfo)))
      if (res.statusCode !== '200') {
        return this.$message.error('获取数据失败！')
      }
      this.tableData = res.data
      this.total = +res.pageBean.pageDataCount
      if (resolve) {
        resolve([])
      }
      // console.log(res.data);
    },
    menuChange (menu) {
      console.log(menu)
      const childIds = []
      this.rekursivesMenus(menu, childIds)
      console.log(this.menuIds, menu.uuid, 'menu.uuid', childIds, 'childIds')
      if (this.menuIds.indexOf(menu.uuid) !== -1) {
        for (let i = 0; i < childIds.length; i++) {
          const index = this.menuIds.indexOf(childIds[i])
          if (index !== -1) {
            this.menuIds.splice(index, 1)
          }
        }
      } else {
        for (let i = 0; i < childIds.length; i++) {
          const index = this.menuIds.indexOf(childIds[i])
          if (index === -1) {
            this.menuIds.push(childIds[i])
          }
        }
      }
      this.$refs.tree.setCheckedKeys(this.menuIds)
    },
    // 获取所有菜单
    async getMenuList () {
      const { data: res } = await this.$http.post('/userManageServer/user/selectAllMenuList', {
        applicationCode: '10000'
      })
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取菜单列表失败')
      this.menuList = res.data
      this.allMenu(res.data)
    },
    // 点击表格项触发
    handleCurrent (v) {
      if (v) {
        this.selectForm = _.cloneDeep(v)
      }

      console.log(v)
    },
    // 监听添加角色对话框的关闭事件
    addDialogClose () {
      this.$refs.addFormRef.resetFields()
    },
    // 点击按钮，添加角色---已调试完成
    addParams () {
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.post('/userManageServer/role/addRole', JSON.parse(JSON.stringify(this.addForm)))
        console.log(res)
        if (res.statusCode !== '200') {
          return this.$message.error('添加失败！')
        }
        this.$message.success('添加成功!')
        // 关闭弹窗
        this.addDialogVisible = false
        // 重新渲染页面
        this.getCateList()
      })
    },
    // 点击查看详情按钮触发
    look (v) {
      this.disabled = true
      this.editForm = _.cloneDeep(v)
      this.editDialogVisible = true
    },
    // 点击编辑按钮触发
    edit (v) {
      this.disabled = false
      this.editForm = _.cloneDeep(v)
      this.editDialogVisible = true
    },
    // 编辑角色--确定按钮
    async editParams () {
      if (this.disabled) { return this.editDialogVisible = false } else {
        // 进行编辑操作
        const { data: res } = await this.$http.post('/userManageServer/role/updateRole', JSON.parse(JSON.stringify(this.editForm)))
        // 判断请求是否成功
        if (res.statusCode !== '200') return this.$message.error('修改失败')
        // 成功提示
        this.$message.success('修改成功')
        // 重新渲染数据

        this.editDialogVisible = false
        this.getCateList()
      }
    },
    // 监听编辑角色对话框的关闭事件
    editDialogClose () {
      this.$refs.editFormRef.resetFields()
    },
    // 监听权限对话框的关闭事件
    limitDialogClosed () {
      // this.selectForm = { uuid: '' }
    },
    // 点击删除按钮触发---已调试完成
    removeBtn () {
      // 判断是否选择编删除对象
      if (this.delForm.uuid === '') return this.$message.error('请先选择删除对象')
      // 显示删除确认框
      this.open(this.delForm)
    },
    open (e) {
      this.$confirm(`是否确认删除：${e.roleName}`, '删除角色', {
        confirmButtonText: '确定删除',
        cancelButtonText: '取消删除',
        type: 'warning'
      }).then(async () => {
        try {
          console.log(e.uuid)
          const { data } = await this.$http.post('/userManageServer/role/deleteRole', {
            uuid: e.uuid
          })
          // 判断是否删除成功
          if (data.statusCode !== '200') return this.$message.error('删除角色失败')
          // this.delForm = { uuid: '', userName: '' }
          // 成功提示
          this.$message.success('删除角色成功')
          // 刷新视图
          this.getCateList()
        } catch (e) {
          // console.log(e)
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 点击查询按钮触发
    async selectBtn () {
      // 判断是否输入角色名称
      if (this.form.rolename === '') return this.$message.error('请输入角色名称')
      this.queryInfo.pageNo = 1
      const dataTex = { ...this.form, ...this.queryInfo }
      const { data: res } = await this.$http.post('/userManageServer/role/selectRoleList', dataTex)
      if (res.statusCode !== '200') {
        return this.$message.error('获取数据失败！')
      }
      console.log(res)
      this.tableData = res.data
      this.total = +res.pageBean.pageDataCount
      this.delForm = { uuid: '' }
    },
    // 点击设置权限触发
    setBtn () {
      // 判断是否选中角色
      if (this.selectForm.uuid === '') return this.$message.error('请先选择编辑对象')
      this.limitDialogVisible = true
      this.$nextTick(() => {
        this.$refs.tree.setCheckedKeys([])
        this.menuIds = []
        this.rekursivesMenus(this.selectForm, this.menuIds)
      })

    //   setTimeout(_ => {
    //     that.$refs.tree.setCheckedKeys(data)
    //   }, 0)
    },
    // 递归菜单
    rekursivesMenus (menu, menuIds) {
      menuIds.push(menu.uuid)
      if (menu.oneMenusList) {
        menu.oneMenusList.forEach(item => {
          menuIds.push(item.uuid)
          this.rekursivesMenus(item, menuIds)
        })
      }
      if (menu.twoMenusList) {
        menu.twoMenusList.forEach(item => {
          menuIds.push(item.uuid)
          this.rekursivesMenus(item, menuIds)
        })
      }
    },

    // 设置权限--确定按钮
    async editlimit () {
      console.log(this.permission)
      const { data: res } = await this.$http.post('/userManageServer/role/addRoleMenu', {
        menuIdList: this.permission,
        roleId: this.selectForm.uuid,
        createBy: sessionStorage.getItem('userId')
      })
      // console.log(res);
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('设置失败')
      // 成功提示
      this.$message.success('设置成功')
      // 重新渲染数据
      // this.getCateList()
      // this.allMenu(JSON.parse(sessionStorage.getItem('menuList')).twoMenusList)
      this.getList()
      // sessionStorage.setItem('menuList', this.asyncMenuList)
      // this.getCateList()
      // location.reload()
      // 关闭弹框
      // this.limitDialogVisible = false
    },
    // 获取已勾选的节点
    handleCheckChange (data, is, son) {
      this.permission = this.$refs.tree.getCheckedKeys().concat(this.$refs.tree.getHalfCheckedKeys())
    },
    // 递归遍历出菜单权限
    allMenu (v) {
      v.forEach(item => {
        this.menu.push(item.uuid)
        if (item.twoMenusList && item.twoMenusList.length > 0) {
          this.allMenu(item.twoMenusList)
        }
      })
    },
    // 每页显示数量变化
    handleSizeChange (newSize) {
      this.queryInfo.pageSize = newSize
      this.getCateList()
    },
    // 当前页码变化
    handleCurrentChange (newPage) {
      this.queryInfo.pageNo = newPage
      this.getCateList()
    },
    // 给表格头部设定背景色
    rowClass ({ row, rowIndex }) {
      return 'background:#ccc'
    },
    // 添加用户--确定按钮---已调试完成
    addUser () {
      // 预校验
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) return
        // 调接口，发送添加客户请求
        const postFomr = JSON.parse(JSON.stringify(this.addForm))
        if (this.node.data) {
          postFomr.baseId = this.node.data.corpId
        }
        const { data } = await this.$http.post('/userManageServer/role/addRole', postFomr)
        // 判断请求是否成功
        if (data.statusCode !== '200') return this.$message.error('添加失败')
        // 成功提示
        this.$message.success('添加成功')
        // 重新渲染数据
        this.getCateList()
        // 关闭弹框
        this.addDialogVisible = false
      })
    },
    getPermission () { // 模拟接口 获取 权限数据集合
      // 模拟获取的数据 有以下几个权限
      const perms = ['search', 'add', 'edit', 'delete']
      // 用于把权限集合提交到 actions 中的 SET_PERMISSION 函数
      // 第一个为函数名，后面的参数为 我们需要提交的参数，可以是多个
      this.$store.dispatch('SET_PERMISSION', perms)
    },
    // 获取菜单权限
    async getList () {
      const { data: res } = await this.$http.post('/userManageServer/user/selectMenuList', {
        applicationCode: '10000',
        userId: sessionStorage.getItem('userId')
      })
      if (res.statusCode !== '200') return this.$message.error('获取用户菜单失败')
      if (res.data instanceof Array) {
        var headerNav = res.data
        // var headerNav = res.data.filter(function (item, index, array) {
        //   return item.menuName !== '大屏'
        // })
        sessionStorage.setItem('headerNav', JSON.stringify(headerNav))
        var nav = JSON.parse(sessionStorage.getItem('headerNav'))
        var system = nav.filter(function (item, index, array) {
          return item.menuUrl === '/system'
        })
        sessionStorage.setItem('menuList', JSON.stringify(system[0].twoMenusList))
      }
      this.btns = []
      this.getbtnList(res.data)
      this.reload()
    },
    // 获取按钮权限
    getbtnList (v) {
      v.forEach(item => {
        if (item.twoMenusList && item.twoMenusList.length > 0) {
          this.getbtnList(item.twoMenusList)
        } else {
          this.btns.push(item.uuid)
        }
      })
      sessionStorage.setItem('btnList', JSON.stringify(this.btns))
    },
    // 全选按钮
    checkAll () {
      this.$refs.tree.setCheckedKeys(this.menu)
    },
    // 反选按钮
    InvertSelection () {
      var b = []
      this.menu.forEach(item => {
        if (!this.permission.includes(item)) {
          b.push(item)
        }
      })
      this.$refs.tree.setCheckedKeys(b)
    }
  }
}
</script>
<style lang="less" scoped>
.characterManage {
  // 卡片A区
  .card-one {
    margin-top: 20px;
    margin-bottom: 30px;
    // width: 1470px;
    padding: 0;
    display: flex;
    .Abtn {
      margin-left: 40px;
    }
  }
  // 卡片B区
  .card-two {
    // width: 1470px;
    .Bbtn {
      height: 54px;
    }
    .cbtn {
      .paging {
        text-align: right;
        margin-top: 20px;
      }
      /deep/ .el-table {
        .el-table__row--striped td {
          background-color: #e4eaec !important;
        }
        .current-row > td {
          background-color: #66b1ff !important;
        }
      }
    }
    .edit-dialog,
    .add-dialog {
      .dialog-Title {
        color: #0097fe;
        font-size: 20px;
        font-weight: 700;
      }
      /deep/ .el-form {
        .subtitle {
          color: #0097fe;
          font-size: 14px;
          font-weight: 700;
          margin-bottom: 20px;
          padding-left: 6px;
          border-left: 5px solid #0097fe;
        }
        .el-row {
          display: flex;
          .el-form-item {
            flex: 1;
            display: flex;
            .el-form-item__content {
              flex: 1;
              .el-input {
                width: 100%;
                .el-input__inner {
                  width: 100%;
                }
              }
              .el-select {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

// .el-form {
//     padding-top: 25px;
// }
.tips{

}
</style>
